import React, { useState } from "react";
import Jobin from "jobin-client";
import { PulseLoader } from "react-spinners";
import { ReCaptcha } from "react-recaptcha-v3";
import { connect } from 'react-redux';
import { apiId, apiKey, jsKey, urlServer } from '../components/constants/credentials';
import { savePhone, saveName, saveEmail, saveObjectId, hasEmail } from '../store/actions/userAction';
import "../styles/step-controlls.css";
import Parse from 'parse';
import { getParameterByName } from "../utils/formatter";
import { selectBusinessColor } from "../utils/image";

//
//PRODUCTION
const fromMaster = false;
const recaptchaToken = "6Le1c6sUAAAAALi5gQEp9MobTDkWLumVuI2kRtJU";
/*/
//TEST
const fromMaster = true;
// const recaptchaToken = "6LeqJKsUAAAAADyU6BwnR4kGAKdvxToDrIfITWt4";
/**/

function PhoneModal(props) {
    const [areaCode, setAreaCode] = useState("+34");
    const [phone, setPhone] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [phoneDisabledBtn, setPhoneDisabledBtn] = useState(true);
    const [isConfirmedTerms, setIsConfirmedTerms] = props.checkAllowedBusiness(getParameterByName("business"))  ? useState(false) : useState(true);
    const [isDataSharingConsent, setIsDataSharingConsent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadRecaptcha, setLoadRecaptcha] = useState(false);
    // const [token, setToken] = useState("");

    const next = () => {
        if (!phoneError) props.updateStep(props.step + 1);
    };

    const onClickHandler = () => {
        if (!phoneError && phoneError !== "") {
            // setLoadRecaptcha(true);
            setPhoneDisabledBtn(true);
            setLoading(true);
            sendSMS();
        }
    };

    const verifyCallback = recaptchaToken => {
        if (recaptchaToken !== "") {
            // setToken(recaptchaToken);
            sendSMS();
        } else setPhoneError(false);
    };

    const sendSMS = async () => {
        try {
            
            const businessId = getParameterByName("business");

            const result = await Jobin.Client.sendSMSCode(areaCode + phone, fromMaster, false);

            if (result.status === "ok") {
                searchUser(result.result.id);
                setPhoneDisabledBtn(false);
                setLoading(false);
                setPhoneError(false);
                props.mixpanel.track("Codigo verificacion enviado");
                props.update({
                    type: "phone",
                    areaCode: areaCode,
                    phone: phone,
                    user: result.result
                });
                Parse.initialize(apiId, jsKey);
                Parse.serverURL = urlServer;
                Parse.User.enableUnsafeCurrentUser();

                const businessId = getParameterByName("business");

                if(businessId != null && businessId != "null" && businessId == "iopoWDLaeq"  ){    
                    let data = {
                        userId:result.result.id,
                        businessId:businessId,
                        isDataSharingConsent:isDataSharingConsent
                    }

                    try {
                        await Parse.Cloud.run("dataSharingConsent", data);
                    } catch (error) {
                        console.error(error)
                    }
                }
                next();
            } else if (result.status === "error" && result.result.contains("Jobin Pro")){
                props.updateStep(7);

            }else{ 
                props.updateStep(5);
            }
        } catch (error) {
            console.error(error)
            setPhoneDisabledBtn(false);
            setLoading(false);
            setPhoneError(true);
        }
    };

    const searchUser = async (id) => {
        Parse.initialize(apiId, jsKey);
        Parse.serverURL = urlServer;
        Parse.User.enableUnsafeCurrentUser();
        let data = {
            id
        }

        let user = await Parse.Cloud.run("getUserById", data);
        if(user && user.get("Name")){
            props.saveName(user.get("Name"));
            props.hasEmail(true);
            props.saveObjectId(id);
            localStorage.removeItem('Parse/ExxNZxyGaH3MmDYWIwKmg6okGr3VWySlxp4Dk16P/currentUser');
        } else {
            props.saveObjectId(id);
            props.hasEmail(false);
        }
    }

    const selectHandler = e => {
        setAreaCode(e.target.value);
    };

    const validatePhone = e => {
        setPhone(e.target.value);
        if (/^([0|+(34|351|33|352|44|49|42|39|41))?([0-9]{9})/.test(e.target.value)){
            setPhoneError(false);
            props.savePhone(e.target.value);
            if (isConfirmedTerms) {
                setPhoneDisabledBtn(false);
            }
        }
        else setPhoneError(true);
    };
    const handleCheckboxChangeTerms = () => {
        setIsConfirmedTerms(!isConfirmedTerms);
        if (isConfirmedTerms) {
            setPhoneDisabledBtn(true);
        } else {
            setPhoneDisabledBtn(false);
        }
    };

    const close = (
        <svg width="40px" height="40px" viewBox="0 0 40 40">
            <defs>
                <path
                    d="M10.4141,9 L16.7781,2.636 C17.1691,2.245 17.1691,1.612 16.7781,1.222 C16.3881,0.831 15.7551,0.831 15.3641,1.222 L9.0001,7.586 L2.6361,1.222 C2.2451,0.831 1.6121,0.831 1.2221,1.222 C0.8311,1.612 0.8311,2.245 1.2221,2.636 L7.5861,9 L1.2221,15.364 C0.8311,15.755 0.8311,16.388 1.2221,16.778 C1.4171,16.974 1.6731,17.071 1.9291,17.071 C2.1851,17.071 2.4401,16.974 2.6361,16.778 L9.0001,10.414 L15.3641,16.778 C15.5601,16.974 15.8151,17.071 16.0711,17.071 C16.3271,17.071 16.5831,16.974 16.7781,16.778 C17.1691,16.388 17.1691,15.755 16.7781,15.364 L10.4141,9 Z"
                    id="path-1"
                />
                <path
                    d="M21.4141,20 L27.7781,13.636 C28.1691,13.245 28.1691,12.612 27.7781,12.222 C27.3881,11.831 26.7551,11.831 26.3641,12.222 L20.0001,18.586 L13.6361,12.222 C13.2451,11.831 12.6121,11.831 12.2221,12.222 C11.8311,12.612 11.8311,13.245 12.2221,13.636 L18.5861,20 L12.2221,26.364 C11.8311,26.755 11.8311,27.388 12.2221,27.778 C12.4171,27.974 12.6731,28.071 12.9291,28.071 C13.1851,28.071 13.4401,27.974 13.6361,27.778 L20.0001,21.414 L26.3641,27.778 C26.5601,27.974 26.8151,28.071 27.0711,28.071 C27.3271,28.071 27.5831,27.974 27.7781,27.778 C28.1691,27.388 28.1691,26.755 27.7781,26.364 L21.4141,20 Z"
                    id="path-3"
                />
            </defs>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <circle id="circle" fill="transparent" cx="20" cy="20" r="20" />
                <g transform="translate(11.000000, 11.000000)">
                    <mask id="mask-2" fill="white">
                        <use xlinkHref="#path-1" />
                    </mask>
                    <g />
                </g>
                <mask fill="white">
                    <use xlinkHref="#path-3" />
                </mask>
                <use fill="#444444" xlinkHref="#path-3" />
            </g>
        </svg>
    );

    // const checkbox = (
    //     <svg width="20px" height="20px" viewBox="0 0 24 24">
    //         <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    //             <g>
    //                 <polygon points="0 24 24 24 24 0 0 0" />
    //                 {contactChat ? (
    //                     <React.Fragment>
    //                         <path
    //                             d="M19.3333333,23 L4.66666667,23 C2.64175,23 1,21.35825 1,19.3333333 L1,4.66666667 C1,2.64175 2.64175,1 4.66666667,1 L19.3333333,1 C21.35825,1 23,2.64175 23,4.66666667 L23,19.3333333 C23,21.35825 21.35825,23 19.3333333,23"
    //                             fill="#048285"
    //                         />
    //                         <path
    //                             d="M10.0552,13.6074 L8.2192,11.7714 C7.8282,11.3804 7.1962,11.3804 6.8052,11.7714 C6.4142,12.1624 6.4142,12.7954 6.8052,13.1854 L9.3482,15.7284 C9.7392,16.1194 10.3712,16.1194 10.7622,15.7284 L16.8052,9.6854 C17.1962,9.2954 17.1962,8.6624 16.8052,8.2714 C16.4142,7.8804 15.7822,7.8804 15.3912,8.2714 L10.0552,13.6074 Z"
    //                             fill="#FFFFFF"
    //                         />
    //                     </React.Fragment>
    //                 ) : (
    //                     <path
    //                         d="M19.3333333,1 L4.66666667,1 C2.6445,1 1,2.6445 1,4.66666667 L1,19.3333333 C1,21.3555 2.6445,23 4.66666667,23 L19.3333333,23 C21.3555,23 23,21.3555 23,19.3333333 L23,4.66666667 C23,2.6445 21.3555,1 19.3333333,1 M19.3333333,2.83333333 C20.3453333,2.83333333 21.1666667,3.65466667 21.1666667,4.66666667 L21.1666667,19.3333333 C21.1666667,20.3453333 20.3453333,21.1666667 19.3333333,21.1666667 L4.66666667,21.1666667 C3.65466667,21.1666667 2.83333333,20.3453333 2.83333333,19.3333333 L2.83333333,4.66666667 C2.83333333,3.65466667 3.65466667,2.83333333 4.66666667,2.83333333 L19.3333333,2.83333333"
    //                         fill="#D2D2D2"
    //                     />
    //                 )}
    //             </g>
    //         </g>
    //     </svg>
    // );

    return (
        <div className="modal">
            <div className="close-modal" onClick={() => props.hideModal(false)}>
                <button className="nostyle">{close}</button>
            </div>
            <h4 className="title">{props.title}</h4>
            <p className="excerpt">{props.excerpt}</p>
            <div className="phone-container">
                <div className="row">
                    <div className="col-sm-3">
                        <select
                            className="area-code-select"
                            onChange={selectHandler}
                            value={areaCode}
                        >
                            <option id="es" value="+34">
                                🇪🇸+34
                            </option>
                            <option id="pr" value="+351">
                                🇵🇹+351
                            </option>
                            <option id="fr" value="+33">
                                🇫🇷+33
                            </option>
                            <option id="lx" value="+352">
                                🇱🇺+352
                            </option>
                            <option id="gr" value="+49">
                                🇩🇪+49
                            </option>
                            <option id="uk" value="+44">
                                🇬🇧+44
                            </option>
                            <option id="it" value="+39">
                                🇮🇹+39
                            </option>
                            <option id="ch" value="+41">
                                🇨🇭+41
                            </option>
                        </select>
                    </div>
                    <div className="col-sm-9">
                        <input
                            className={phoneError ? "input error" : "input"}
                            type="tel"
                            placeholder={props.placeholder}
                            value={phone}
                            onChange={validatePhone}
                        />
                        <span className={phoneError ? "error" : "hidden"}>
                            El teléfono que has introducido no es correcto o pertenece a un profesional
                        </span>
                    </div>
                </div>
            </div>
            <div className="one-row-button terms" style={{display:  "block" }}>
                <label htmlFor="confirm-terms">
                    <input

                        id="terms-confirmed"
                        type="checkbox"
                        checked={isConfirmedTerms}
                        onChange={handleCheckboxChangeTerms}
                    />
                    {/*{checkbox}*/}
                    Acepto <a href={"https://www.wollyhome.com/legales/politica-privacidad"} target={"_blank"}>la política de privacidad</a> de Wolly Corp.
                </label>
            </div>
            {getParameterByName("business") === "iopoWDLaeq" && (<div className="one-row-button terms" style={{display:  "block" }}>
                <label htmlFor="sharing-data-confirmed">
                    <input

                        id="sharing-data-confirmed"
                        type="checkbox"
                        checked={isDataSharingConsent}
                        onChange={()=>setIsDataSharingConsent(!isDataSharingConsent)}
                    />
                    Consiento y autorizo expresamente la cesión de mis datos personales a la entidad UFESA, S.A., para el envío de comunicaciones comerciales, realizadas a través de correo electrónico o medios de comunicación electrónica equivalentes, relativas a sus productos y servicios.
                </label>
            </div>)}
            <div className="one-row-button conditions" style={{display: getParameterByName("business") === "ZF5WZAbvQ6" || getParameterByName("business") === "kqJtDU5D9b" ? "block" : "none"}}>
                <small>Una vez aceptada la política de privacidad y al clicar en Continuar la información asociada a su servicio ya será de responsabilidad y gestionada por parte de Wolly Corp SL. En caso de que sea necesario Wolly Corp SL le podrá solicitar datos adicionales de carácter personal.</small>
                {getParameterByName("business") === "kqJtDU5D9b" ? (<small style={{margin:"10px auto"}}><p></p> Acepto que en caso de que finalmente decida formalizar la contratación del servicio con Wolly, AQ Acentor quedará totalmente desvinculado de dicha relación comercial y quedará totalmente indemne frente a cualquier reclamación que pudiera surgir en relación con la Plataforma de Wolly y/o con los servicios contratados.</small> ):null}
            </div>
            <div className="one-row-button">
                {/* <label htmlFor="contact-chat">
                            <input
                                className="native-hidden"
                                id="contact-chat"
                                type="checkbox"
                                checked={confirmedTerms}
                                onChange={() => setConfirmedTerms(!confirmedTerms)}
                            />
                            {checkbox}
                            {props.checkbox}
                        </label> */}
                <button
                    type="button"
                    onClick={onClickHandler}
                    disabled={phoneDisabledBtn && !isConfirmedTerms}
                    style={{ backgroundColor:selectBusinessColor(getParameterByName("business")), color:  (getParameterByName("business") == "lMyRIzN79G" || getParameterByName("business") == "ZF5WZAbvQ6" || getParameterByName("business") == "kqJtDU5D9b") ? "white": "black" }}
                >
                    {loading ? (
                        <PulseLoader
                            margin={"4px"}
                            sizeUnit={"px"}
                            size={10}
                            color={"#fff"}
                            loading={loading}
                        />
                    ) : (
                        props.button
                    )}
                </button>
            </div>
            {loadRecaptcha && (
                <ReCaptcha
                    sitekey={recaptchaToken}
                    action="action_name"
                    verifyCallback={verifyCallback}
                />
            )}
        </div>
    );
}

const mapStateToProps = state => {
    return {
        isPelayo: state.businessCodeReducer.isPelayo
    }
}

const mapDispathcToProps = dispatch => {
    return {
        savePhone: (phone) => dispatch(savePhone(phone)),
        saveName: (name) => dispatch(saveName(name)),
        saveEmail: (email) => dispatch(saveEmail(email)),
        saveObjectId: (objectId) => dispatch(saveObjectId(objectId)),
        hasEmail: (value) => dispatch(hasEmail(value))
    }
}

export default connect(mapStateToProps, mapDispathcToProps)(PhoneModal);