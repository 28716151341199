import React, { Component } from "react";
import Jobin from "jobin-client";

import Nav from "../components/Nav";
import StepTip from "../components/StepTip";
import StepControlls from "../components/StepControlls";
import BreadCrumb from "../components/BreadCrumb";
import { jobDescription } from '../store/actions/jobAction';
import {getParameterByName} from "../utils/formatter";

import "../styles/service-description.css";

import stepIconLeft from "../assets/step-icon-left.svg";
import stepIconLeftPelayo from "../assets/Pelayo-icons/bombilla.png";
import { connect } from "react-redux";

class ServiceDescription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: this.props.message || '',
            minChar: this.props.minChar || 0,
            maxChar: this.props.maxChar || 100,
            tooltip: this.props.tooltip || '',
            styleType: this.props.styleType || '',
            error: this.props.error || '',
            disabledBtn: this.props.disabledBtn || false
        };
    }

    validateDescription = (e) => {
        const value = e.target.value;
        let tooltip = value.length < this.state.minChar ? `Faltan ${this.state.minChar - value.length} caractereres` : "";

        this.setState({
            message: value,
            tooltip: tooltip,
            styleType: "primary",
            error: value.length < this.state.minChar,
            disabledBtn: value.length < this.state.minChar
        });

        /*if (value.length < this.state.minChar) {
            error = true;
            let charsLeft = this.state.minChar - value.length;
            if (charsLeft === 1) tooltip = `Falta ${charsLeft} caracter`;
            else tooltip = `Faltan ${charsLeft} caracteres`;

        } else if (value.length <= this.state.maxChar) {
            error = false;
            tooltip = `${value.length}/${this.state.maxChar}`;

            this.setState({
                message: value,
                tooltip: tooltip,
                styleType: "success",
                error: error,
                disabledBtn: false
            });
        } else {
            error = true;
            tooltip = "Límite superado";

            this.setState({
                message: value,
                tooltip: "Límite superado",
                styleType: "error",
                error: error
            });
        }

        return error;*/
    };

    update = childData => {
        this.props.mixpanel.track("Paso 3 completado");

        // push state to parent to save the content
        this.props.update(childData);
        this.props.jobDescription(childData);
    };

    componentDidMount() {
        if (
            !(this.props.location.state && "inner" in this.props.location.state)
        )
        Jobin.Client.logOut().then(result => {
            
        });
    }

    render() {
        const { message, tooltip, styleType, error } = this.state;
        return (
            <div id="service-description" className="section">
                <Nav />
                <div className="margin-top-72px">
                    <BreadCrumb
                        history={this.props.history}
                        location={this.props.location}
                    />
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <h4 className="title">Danos más detalles</h4>
                                <p className="hidden-sm hidden-xs">
                                    Da al profesional detalles significativos
                                    que creas que puedan ayudarle a entender
                                    mejor lo que necesitas.
                                </p>
                                <p className="hidden-lg">
                                    Cuanta más información puedas darle al
                                    profesional, antes podrá resolver tu
                                    solicitud.
                                </p>
                                <div className="content">
                                    <textarea
                                        name="description"
                                        placeholder="Escribe algunos detalles sobre tu solicitud"
                                        className="textarea"
                                        onChange={this.validateDescription}
                                        value={message}
                                    />
                                    <div className="text-right">
                                        <span className={styleType}>
                                            {tooltip}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-offset-1 col-md-4">
                                <StepTip
                                    iconLeft={getParameterByName("business") == "W8fBSZcVw5" ?stepIconLeftPelayo:stepIconLeft}
                                    title="Los primeros 20 caracteres serán el título de tu oferta"
                                    excerpt="Intenta ser conciso para que tu solicitud sea más fácil de comprender para los profesionales de un solo vistazo."
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <StepControlls
                    history={this.props.history}
                    location={this.props.location}
                    page={this.props.step}
                    disabled={this.state.disabledBtn}
                    next="picture"
                    prev={"category/" + this.props.middleCategory}
                    nextTrigger={error}
                    nextTitle="Siguiente"
                    cState={this.state}
                    update={this.update}
                />
                <img
                    src="https://pixel.mathtag.com/event/img?mt_id=1443636&amp;mt_adid=230174&amp;mt_exem=&amp;mt_excl=&amp;v1=&amp;v2=&amp;v3=&amp;s1=&amp;s2=&amp;s3="
                    width="1"
                    height="1"
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {

    }
}

const mapDispatchToProps = dispatch => {
    return {
        jobDescription: (data) => dispatch(jobDescription(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceDescription);